<template>
  <v-container v-if="!loading">
    <h3>Admin Settings</h3>
    <v-row>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Setting</div>
              <v-list-item-title class="headline mb-1"
                >Categories</v-list-item-title
              >
              <v-list-item-subtitle
                >Manage Food Catgeories like Pizza, Pasta,
                etc.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/admin/settings/categories" text>Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Setting</div>
              <v-list-item-title class="headline mb-1">Diets</v-list-item-title>
              <v-list-item-subtitle
                >Manage Food Diet like Organic, Vegan,
                etc.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/admin/settings/diet" text>Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Setting</div>
              <v-list-item-title class="headline mb-1"
                >Adjust Admin Values</v-list-item-title
              >
              <v-list-item-subtitle
                >View and adjust global environment
                values.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/admin/env" text>Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Cities</div>
              <v-list-item-title class="headline mb-1"
                >Operating Cities</v-list-item-title
              >
              <v-list-item-subtitle
                >List of cities active in TakeIn.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/admin/cities/list" text>Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Carousels</div>
              <v-list-item-title class="headline mb-1"
                >Manage Carousels</v-list-item-title
              >
              <v-list-item-subtitle>List App Manage.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions class="card-actions">
            <v-btn to="/admin/carousel/list" text>Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Communities</div>
              <v-list-item-title class="headline mb-1"
                >Communities</v-list-item-title
              >
              <v-list-item-subtitle
                >List of communities in TakeIn.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/admin/communities/list" text>Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DeliverySetup from './DeliverySetup'
import FoodSetup from './FoodSetup'

import { mapGetters } from 'vuex'

/*
Don’t send me anything (aside from vital account emails such as password reset notifications)
*/
export default {
  components: {
    DeliverySetup,
    FoodSetup
  },
  computed: {
    ...mapGetters({
      isChef: 'isChef',
      user: 'user',
      loading: 'loading'
    })
  },
  mounted() {
    this.$store.dispatch('getUserSettings').then(() => {})
  }
}
</script>
