<template>
  <v-card v-if="!loading">
    <v-card-text class="grey lighten-3">
      Sub Ingredient List
      <v-row>
        <v-col cols="12" sm="8">
          <v-data-table
            :items="subIngredientList"
            :headers="headers"
            item-key="name"
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item[0] | capitalize }}</td>
              <td>{{ toList(props.item[1]) }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      headers: [
        { text: 'Ingredient', value: 'Ingredient', sortable: false },
        { text: 'Sub Ingredient', value: 'Sub Ingredient', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      subIngredientList: 'subIngredientList'
    })
  },
  mounted() {
    //, this.maxDeliveryRange,this.deliveryCharge } = this.settings
  },
  methods: {
    saveSubIngredients() {},
    toList(list) {
      return list.toString().replace(/,/g, ', ')
    }
  }
}
</script>
