<template>
  <v-row class="pa-3">
    <v-col cols="12"> Address: </v-col>
    <v-col cols="12"> {{ formatted }} </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    location: {
      Type: Object
    }
  },
  computed: {
    formatted() {
      if (!this.location) {
        return ''
      }
      return `${this.location.address1} ${
        this.location.address2 ? this.location.address2 : ''
      } ${this.location.city ? this.location.city : ''} ${
        this.location.state ? this.location.state : ''
      } ${this.location.zipcode ? this.location.zipcode : ''}`
    }
  }
}
</script>
