<template>
  <v-card v-if="!loading && profile">
    <v-card-text class="grey lighten-3">
      <v-switch
        label="I can deliver my products"
        v-model="canDeliver"
      ></v-switch>
      <v-row v-if="canDeliver">
        <v-col cols="12" sm="4" class="mr-3">
          <v-text-field
            label="Extra Charge"
            hint="This is will be added to total price"
            v-model="deliveryCharge"
            prefix="$"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            label="Maximum Delivery range"
            v-model="maxDeliveryRange"
            type="number"
            suffix="Miles"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="grey lighten-3">
      <v-switch
        label="I allow pickup from my address"
        v-model="canPickup"
      ></v-switch>
      <template v-if="canPickup">
        <v-row class="mb-3">
          <v-col cols="12" sm="8">
            <v-icon>alert</v-icon>
            <formatted-address :location="profile.address" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="5">
            <strong>Earliest Pickup time</strong>
            <v-time-picker
              v-model="pickupTimeEarliest"
              width="200"
              landscape
              type="month"
              class="mt-3"
            ></v-time-picker>
          </v-col>
          <v-col cols="12" sm="5">
            <strong>Latest Pickup time</strong>
            <v-time-picker
              v-model="pickupTimeLatest"
              width="200"
              landscape
              type="month"
              class="mt-3"
            ></v-time-picker>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="saveDeliverySettings" color="primary">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import FormattedAddress from '@/components/common/FormattedAddress'
export default {
  components: {
    FormattedAddress
  },
  data() {
    return {
      canDeliver: false,
      canPickup: true,
      pickupTimeEarliest: '09:00',
      pickupTimeLatest: '20:00',
      maxDeliveryRange: 10,
      deliveryCharge: 5
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      settings: 'userSettings'
    })
  },
  mounted() {
    if (this.settings) {
      this.canDeliver = this.settings.canDeliver
      this.maxDeliveryRange = this.settings.maxDeliveryRange
      this.deliveryCharge = this.settings.deliveryCharge
      this.canPickup = this.settings.canPickup
      this.pickupTimeEarliest = this.settings.pickupTimeEarliest
      this.pickupTimeLatest = this.settings.pickupTimeLatest
    } else {
      console.log('Setting is empty!')
      //this.$store.dispatch('loadProfile')
    }
    //, this.maxDeliveryRange,this.deliveryCharge } = this.settings
  },
  methods: {
    saveDeliverySettings() {
      this.$store.dispatch('saveDeliverySettings', {
        canDeliver: this.canDeliver,
        maxDeliveryRange: this.maxDeliveryRange,
        deliveryCharge: this.deliveryCharge,
        canPickup: this.canPickup,
        pickupTimeEarliest: this.pickupTimeEarliest,
        pickupTimeLatest: this.pickupTimeLatest
      })
    }
  }
}
</script>
